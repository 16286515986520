// Packages
import { useRouter } from 'next/router'
import Head from 'next/head'
import { useIntl } from 'react-intl'
import { chakra } from '@chakra-ui/react'
// Types
import { SearchTabQuery, SearchTabQuerySchema } from '@reward-platform/shared-schemas'
// Components
import { Tabs, TabContent } from '@reward-platform/lift/components/Tabs'
import SearchCarHireForm from '~/components/search-and-results/SearchCarHireForm'
import SearchExperienceForm from '~/components/search-and-results/SearchExperienceForm'
import SearchFlightForm from '~/components/search-and-results/SearchFlightForm'
import SearchHotelForm from '~/components/search-and-results/SearchHotelForm'
import IntroSection from '~/components/search-and-results/IntroSection'
import { useScrim, ScrimOverlay } from '~/components/shared/Scrim'
import Hero from '~/components/shared/Hero'
import {
  PromotionHero,
  PromotionInlineBanner,
  mapSearchTabToPromotionProduct,
  useIsPromotionComponentEnabled,
} from '~/components/promotions'
// Utils
import {
  getTabPropsFromContent,
  useSearchPageContent,
  useSearchPageRouter,
} from '~/hooks/useSearchPage'
import { usePartner } from '~/context/partner'
import { useUser } from '~/context/user'
import { notEmpty } from '~/utils/filterNull'
import RecommendedSection from '~/components/search-and-results/RecommendedSection'
import { useFeatureFlag } from '~/hooks/useFeatureFlag'
import { flightPageDetails } from '~/utils/googleTagManager/googleTagManagerHelperFunctions'
import { useNavigationAnalytics } from '~/hooks/useNavigationAnalytics'
import FlightAviosOnlyBanner from '~/components/search-and-results/FlightAviosOnlyBanner/FlightAviosOnlyBanner'
import { RichText } from '@reward-platform/lift/components'

const SearchPage = () => {
  const intl = useIntl()
  const router = useRouter()
  const user = useUser()
  const partner = usePartner()
  const { isScrimActive, closeScrim, openScrim } = useScrim()

  const { allTabContent = [], getTabContentByProductSlug } = useSearchPageContent()
  const tabSlugs = allTabContent.map((content) => content.product).filter(notEmpty)
  const { selectedTab, setSelectedTab, isTabEnabled } = useSearchPageRouter(tabSlugs)
  const { onNavigate } = useNavigationAnalytics()

  const flightsRecommendedEnabled = useFeatureFlag('flights-recommended-section')
  const flightsAviosOnlyEnabled = useFeatureFlag('flights-avios-only')

  const t = {
    searchFlights: intl.formatMessage({
      id: 'search-flights-text',
      defaultMessage: 'Search Flights',
    }),
    searchCarHire: intl.formatMessage({
      id: 'search-car-hire-text',
      defaultMessage: 'Search Car Hire',
    }),
    searchExperiences: intl.formatMessage({
      id: 'search-experiences-text',
      defaultMessage: 'Search Experiences',
    }),
  }

  const { hero, intro, productLabel, recommended, trendingDestinations } =
    getTabContentByProductSlug(selectedTab) ?? {}
  const heroImage = hero?.image?.file?.url ?? ''
  const heroLabel = hero?.image?.description ?? hero?.image?.title ?? hero?.heading ?? ''

  const handleOnSearchAnalytics = () => {
    onNavigate({
      navType: 'cta',
      navAction: 'search flights',
      extra: { event: 'navigation', ...flightPageDetails(router, user, partner) },
    })
  }

  const renderSearchComponent = (slug?: SearchTabQuery) => {
    switch (slug) {
      case SearchTabQuerySchema.Enum.hotels:
        return <SearchHotelForm trendingDestinations={trendingDestinations} />
      case SearchTabQuerySchema.Enum.carhire:
        return <SearchCarHireForm buttonText={t.searchCarHire} />
      case SearchTabQuerySchema.Enum.experiences:
        return <SearchExperienceForm buttonText={t.searchExperiences} />
      case SearchTabQuerySchema.Enum.flights:
        return (
          <SearchFlightForm submitText={t.searchFlights} onValidSubmit={handleOnSearchAnalytics} />
        )

      default:
        return null
    }
  }

  const isPromotionHeroEnabled = useIsPromotionComponentEnabled('searchPageHero', {
    product: mapSearchTabToPromotionProduct(selectedTab),
  })

  return (
    <>
      <Head>
        <title>{`${partner.name} -  ${productLabel} Search`}</title>
      </Head>
      {isPromotionHeroEnabled ? (
        <PromotionHero product={mapSearchTabToPromotionProduct(selectedTab)} />
      ) : (
        <Hero src={heroImage} alt={heroLabel}>
          <Hero.Headline>{hero?.heading}</Hero.Headline>
          {hero?.body ? (
            <Hero.Tagline>
              <RichText document={hero.body} />
            </Hero.Tagline>
          ) : null}
        </Hero>
      )}
      <chakra.section
        position="relative"
        zIndex={5}
        mt="-5rem"
        mb="6"
        gridColumn={{ base: 'var(--layout-column-wide)', md: 'var(--layout-column-center)' }}
      >
        <ScrimOverlay active={isScrimActive} />
        <Tabs label="Search" selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
          {allTabContent
            .map((productContent) => {
              const tabProps = getTabPropsFromContent(
                productContent,
                productContent.product ? isTabEnabled(productContent.product) : true
              )
              return tabProps ? (
                <TabContent
                  {...tabProps}
                  onFocus={openScrim}
                  onBlur={closeScrim}
                  key={JSON.stringify(productContent)}
                >
                  {renderSearchComponent(tabProps.slug as SearchTabQuery)}
                </TabContent>
              ) : null
            })
            .filter(notEmpty)}
        </Tabs>
      </chakra.section>
      <PromotionInlineBanner product={mapSearchTabToPromotionProduct(selectedTab)} />
      {selectedTab === SearchTabQuerySchema.Enum.flights && flightsAviosOnlyEnabled && (
        <FlightAviosOnlyBanner />
      )}
      {flightsRecommendedEnabled && recommended && <RecommendedSection {...recommended} />}
      {intro && <IntroSection {...intro} />}
    </>
  )
}

export default SearchPage
