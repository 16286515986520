import { useMemo } from 'react'
import { Box, Image, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import { ContentfulCard } from '@reward-platform/shared-schemas'
import { Location } from '@reward-platform/ancillaries-schemas/location'
import { Chip } from '@reward-platform/lift/components'
import { usePartner } from '~/context/partner'
import useIntl from '~/hooks/useIntl/useIntl'
import { pushEventToDataLayer } from '~/utils/googleTagManager'
import { getPartnerFallbackImageUrl } from '~/utils/getPartnerAssets'

export type PopularDestinationType = ContentfulCard & Location

type PopularDestinationsProps = {
  destinations: PopularDestinationType[]
  onDestinationClick: (destination: PopularDestinationType) => void
  displayInline: boolean
}

export default function PopularDestinations(props: PopularDestinationsProps) {
  const { onDestinationClick } = props

  const { destinations, displayInline } = props

  const partner = usePartner()
  const fallbackSrc = useMemo(() => getPartnerFallbackImageUrl(partner), [partner])
  const styles = useMultiStyleConfig('PopularDestinations')

  const intl = useIntl()

  const t = {
    popularDestinations: intl.formatMessage({
      id: 'trending-destinations',
      defaultMessage: 'Trending Destinations',
    }),
    popular: intl.formatMessage({
      id: 'popular',
      defaultMessage: 'Popular',
    }),
  }

  const handleDestinationClick = (destination: PopularDestinationType) => {
    pushEventToDataLayer('navigation', {
      nav_element: 'hotels trending destinations',
      nav_type: 'cta',
      nav_action: `destination name ${destination.heading} | popular`,
      referrer: document.referrer,
      departure_date: 'not_applicable',
      arrival_date: 'not_applicable',
      origin: 'not_applicable',
      destination: destination.fullName,
    })

    onDestinationClick(destination)
  }

  return (
    <Box __css={styles.container}>
      <chakra.h4>{t.popularDestinations}</chakra.h4>
      <chakra.ul __css={styles.displayGrid} sx={displayInline ? styles.displayInline : undefined}>
        {destinations.map((destination) => (
          <chakra.li
            key={JSON.stringify(destination)}
            data-testid="popular-destination"
            __css={styles.destinationCard}
            onClick={() => handleDestinationClick(destination)}
          >
            <Box>
              <Box __css={styles.destinationLocation}>
                <chakra.h4>{destination.heading}</chakra.h4>
              </Box>
              <Chip variant="primary" sx={styles.chip}>
                {t.popular}
              </Chip>
            </Box>
            <Image
              __css={styles.thumbnail}
              objectFit="cover"
              src={destination.image.file?.url}
              alt={destination.heading}
              fallbackSrc={fallbackSrc}
            />
          </chakra.li>
        ))}
      </chakra.ul>
    </Box>
  )
}
